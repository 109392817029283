html,
body,
#root {
  background-color: #fff;
  margin: 0;
  padding: 0;
  color: #963596;
  user-select: none !important;
  font-family: sans-serif;
}

.rf-tc-title {
  color: var(--rf-color-primary);
  text-align: left !important;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  width: -webkit-fill-available !important;
}

.rf-tc-subtitle {
  text-align: left !important;
  font-weight: bold;
  width: -webkit-fill-available !important;
}
.rf-tc-subtitle {
  text-align: justify !important;
}
